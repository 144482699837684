export const CARLY = {
  time: '19:30 - 22:00',
  description: 'Carly Simone live singing and music ',
  title: "Let's Get the Party Started!",
  genre: [
    'Vocal',
    'Piano',
    'saxophone',
    'DJ',
    'Funk',
    'Jazz',
    'R&B',
    'Live music',
  ],
};
