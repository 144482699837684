import React from 'react';
import classnames from 'classnames';

import Icon from 'components/UI/Icon/index';

import { CARLY_BEFORE } from 'mocks/screver-ocx-program/carlyBefore';

import { section, list, title } from './styles.module.scss';

const CarlyBefore = () => {
  return (
    <section className={section}>
      <ul className={list}>
        {CARLY_BEFORE.map((item) => {
          return (
            <li key={item.title}>
              <p className={classnames(title, 'font-aeroport')}>{item.title}</p>

              <Icon name={item.icon} />
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default CarlyBefore;
