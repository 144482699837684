import React from 'react';
import classnames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import useStaticImage from 'hooks/graphql/useStaticImage';
import Survey from '../Survey/index';
import Icon from 'components/UI/Icon/index';

import {
  modal,
  modalGap,
  header,
  name,
  position,
  image,
  bgImage,
  time,
  slogan,
  description,
  content,
  closeBtn,
  anonymous,
  successStyle,
  headerInner,
} from './styles.module.scss';

const ProgramModal = ({
  data,
  handleVote,
  handleClose,
  speakerCompleted,
  speakerFromStorage,
  isVote,
}) => {
  const getStaticImage = useStaticImage();

  const surveyTitle = `How do you like the ${data.speaker} keynote?`;

  return (
    <div className={modal}>
      <button
        className={closeBtn}
        type="button"
        onClick={handleClose}
        id="close-modal"
      >
        <Icon name="close" />
      </button>
      <div className={classnames(modalGap, header)}>
        <div className={headerInner}>
          <p className={classnames(name, 'font-epiloque')}>{data.speaker}</p>

          <p className={classnames(position, 'font-aeroport')}>
            {data.position}
          </p>
        </div>

        <GatsbyImage
          className={bgImage}
          image={getImage(getStaticImage(`ocx/${data.bgImage}`))}
          alt={''}
        />

        {/* <GatsbyImage
          className={image}
          objectFit="contain"
          image={getImage(getStaticImage(`ocx/${data.photoModal}`))}
          alt={data.speaker + ' ' + data.position}
        /> */}

        <img
          className={image}
          src={`/images/ocx-event-program/${data.photoModal}`}
          alt={data.speaker + ' ' + data.position}
        />
      </div>

      <div className={classnames(modalGap, content)}>
        <p className={classnames(time, 'font-aeroport ocx-prog-main-color')}>
          {data.startTime}-{data.finishTime}
        </p>

        <p className={classnames(slogan, 'font-epiloque')}>{data.title}</p>

        <p className={classnames(description, 'font-aeroport')}>
          {data.description}
        </p>
      </div>

      {data.speaker && !isVote && !speakerFromStorage && speakerCompleted ? (
        <>
          <Survey
            speaker={data.speaker}
            handleVote={handleVote}
            title={surveyTitle}
          />

          <p className={classnames(modalGap, anonymous, 'font-epiloque')}>
            <Icon name="anonymous" />
            anonymously
          </p>
        </>
      ) : (
        <></>
      )}

      {isVote || speakerFromStorage ? (
        <p className={successStyle}>Thanks for the feedback 💙</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProgramModal;
