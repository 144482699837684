import React from 'react';
import classnames from 'classnames';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// import useStaticImage from 'hooks/graphql/useStaticImage';

import { surveyStyle, titleStyle, list, btn } from './styles.module.scss';

const surveyBtns = [
  {
    icon: 'rating-1',
    id: '1',
  },
  {
    icon: 'rating-2',
    id: '2',
  },
  {
    icon: 'rating-3',
    id: '3',
  },
  {
    icon: 'rating-4',
    id: '4',
  },
  {
    icon: 'rating-5',
    id: '5',
  },
];

const Survey = ({
  speaker,
  handleVote,
  title = 'How do you like the keynote?',
}) => {
  // const getStaticImage = useStaticImage();

  return (
    <div className={surveyStyle}>
      <>
        <p className={classnames(titleStyle, 'font-epiloque')}>{title}</p>

        <ul className={list}>
          {surveyBtns.length > 0 ? (
            surveyBtns.map((item) => {
              // const image = (photo) =>
              //   getImage(getStaticImage(`ocx/${photo}.png`));

              return (
                <li key={item.id}>
                  <button
                    type="button"
                    id={speaker + ' ' + item.id}
                    className={btn}
                    onClick={handleVote}
                  >
                    {/* <GatsbyImage image={image(item.icon)} alt={item.icon} /> */}
                    <img
                      src={`/images/ocx-event-program/${item.icon}.png`}
                      alt={item.icon}
                    />
                  </button>
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
      </>
    </div>
  );
};

export default Survey;
