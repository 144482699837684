import React from 'react';
import classnames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import useStaticImage from 'hooks/graphql/useStaticImage';

import { BOXES_LIST } from 'mocks/screver-ocx-program/boxes-list';

import {
  list,
  listItem,
  inner,
  image,
  title,
  room,
  description,
  line,
} from './styles.module.scss';

const BoxList = () => {
  const getStaticImage = useStaticImage();

  return (
    <section>
      <ul className={list}>
        {BOXES_LIST.length > 0 ? (
          BOXES_LIST.map((box, idx) => {
            return (
              <li key={box.title + idx}>
                <div className={listItem}>
                  <GatsbyImage
                    className={image}
                    image={getImage(
                      getStaticImage(`ocx/${box.imageSmall}.png`)
                    )}
                    alt={box.title}
                  />

                  <div className={inner}>
                    <p className={classnames(title, 'font-epiloque')}>
                      {box.title}
                    </p>
                    <p className={classnames(room, 'font-aeroport')}>
                      {box.room}
                    </p>
                    <p className={classnames(description, 'font-aeroport')}>
                      {box.description}
                    </p>
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <></>
        )}
      </ul>

      <p className={line}></p>
    </section>
  );
};

export default BoxList;
