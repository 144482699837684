import React, { useRef } from 'react';
import classnames from 'classnames';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// import useStaticImage from 'hooks/graphql/useStaticImage';
import useDeviceState from 'hooks/useDeviceState';
import { MOBILE_KEY } from 'constants/devices.js';

import { BOXES } from 'mocks/screver-ocx-program/boxes';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import {
  slideStyle,
  imageStyle,
  title,
  room,
  slideTitle,
  line,
  section,
} from './styles.module.scss';
import './swiper.scss';

SwiperCore.use([Pagination]);

const BoxSlider = () => {
  const swiperRef = useRef(null);
  // const getStaticImage = useStaticImage();
  const deviceState = useDeviceState();

  return (
    <section className={section}>
      <p className={classnames(title, 'font-aeroport')}>
        Take a break, connect with people, and check out our theme boxes
      </p>

      <Swiper
        ref={swiperRef}
        slidesPerView={deviceState === MOBILE_KEY ? 1.1 : 1.7}
        spaceBetween={0}
        pagination={{ clickable: true }}
      >
        {BOXES.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className={slideStyle}>
              {/* <GatsbyImage
                className={imageStyle}
                image={getImage(getStaticImage(`ocx/${slide.imageFull}.png`))}
                alt={slide.title}
              /> */}

              <div className={imageStyle}>
                <img
                  className={imageStyle}
                  // src={`images/ocx/${slide.imageFull}.png`}
                  src={`/images/ocx-event-program/${slide.imageFull}.png`}
                  alt={slide.title}
                />
              </div>

              <p className={classnames(room, 'font-aeroport')}>{slide.room}</p>

              <p className={classnames(slideTitle, 'font-epiloque')}>
                {slide.title}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <p className={line}></p>
    </section>
  );
};

export default BoxSlider;
