// extracted by mini-css-extract-plugin
export var list = "styles-module--list--147Il";
export var noLine = "styles-module--noLine--ynnlJ";
export var listItem = "styles-module--listItem--2bAd_";
export var rightGap = "styles-module--rightGap--7K8Zs";
export var content = "styles-module--content--1pg3i";
export var img = "styles-module--img--2Pqty";
export var time = "styles-module--time--V3FQU";
export var marginLarge = "styles-module--marginLarge--2NQk8";
export var title = "styles-module--title--3268q";
export var subtitle = "styles-module--subtitle--2H1mn";
export var speaker = "styles-module--speaker--36qH5";
export var speakerPhoto = "styles-module--speakerPhoto--1SYQw";
export var result = "styles-module--result--3pIjE";
export var showResult = "styles-module--showResult--1qPmE";
export var noHover = "styles-module--noHover--17RZu";