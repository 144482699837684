export const PROGRAM = {
  firstPart: [{
      startTime: '12:30',
      finishTime: '13:30',
      title: 'Registration',
      speaker: null,
      position: null,
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: null,
      slogan: null,
      description: null,
      id: 'Registration'
    },
    {
      startTime: '13:30',
      finishTime: '14:20',
      title: 'Courage for the future – innovation of tomorrow',
      speaker: 'Marc Hauser',
      position: 'professional Swiss skydiver 🪂, artist and entrepreneur',
      photo: 'Marc-Hauser.png',
      photoModal: 'Marc-Hauser-modal.png',
      bgImage: 'modal-bg-4.png',
      rating: false,
      slogan: '',
      description: 'As a pioneer who was the first to skydive through the jet stream, Hauser demonstrates the incredible energy potential of high-altitude wind power. BBC World News accompanied him with a three-part series. New ways of harnessing clean energy are one of the hopes for a sustainable future.',
      id: 'Marc-Hauser'
    },
    {
      startTime: '14:20',
      finishTime: '14:45',
      title: 'Welcome to Screver',
      speaker: 'Christian Fillinger',
      position: 'Founder of Capptoo & Screver',
      photo: 'Christian-Fillinger.png',
      photoModal: 'Christian-Fillinger-modal.png',
      bgImage: 'modal-bg-2.png',
      rating: false,
      slogan: '',
      description: '',
      id: 'Christian-Fillinger'
    },
    {
      startTime: '14:45',
      finishTime: '15:25',
      title: '“Our agile way” – increase agile-maturity based on a self-made assessment',
      speaker: 'Dr. Anja Huber',
      position: 'Agile Support ZHAW Finance & Services',
      photo: 'Dr-Anja-Huber.png',
      photoModal: 'Dr-Anja-Huber-modal.png',
      bgImage: 'modal-bg-2.png',
      rating: null,
      slogan: '',
      description: 'The ZHAW is one of the leading universities of applied sciences in Switzerland with over 3\'500 employees and more than 14\'000 students. ZHAW goes agile and is constantly assessing and improving its new way of working.',
      id: 'Dr-Anja-Huber'
    },
    {
      startTime: '',
      finishTime: '',
      title: 'NPS/CSAT for HCPs',
      subtitle: 'MSD Merck <span>/first group/</span>',
      speaker: null,
      position: null,
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: null,
      slogan: null,
      description: null,
      id: 'NPS/CSAT for HCPs'
    },
    {
      startTime: '15:25',
      finishTime: '15:45',
      title: 'Networking Break',
      subtitle: '',
      speaker: null,
      position: null,
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: null,
      slogan: null,
      description: null,
      id: 'Networking Break'
    },
  ],
  secondPart: [{
      startTime: '15:45',
      finishTime: '16:25',
      title: 'Transformation into a new corporate culture. Taking effective measures to increase employee satisfaction',
      speaker: 'Jannine Hodel',
      position: 'HR Director Stadtcasino Baden Group',
      photo: 'Jannine-Hodel.png',
      photoModal: 'Jannine-Hodel-modal.png',
      bgImage: 'modal-bg-1.png',
      rating: null,
      slogan: '',
      description: 'Stadtcasino Baden and its subsidiaries are one of the largest casino operators in Switzerland and active in both the online and traditional gaming space. More than 300,000 visitors enjoy The House of Entertainment, a glittering world with diverse experiences and first-class services every year.',
      id: 'Jannine-Hodel'
    },
    {
      startTime: '',
      finishTime: '',
      title: 'NPS/CSAT for HCPs',
      subtitle: 'MSD Merck <span>/second group/</span>',
      speaker: null,
      position: null,
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: null,
      slogan: null,
      description: null,
      id: 'NPS/CSAT for HCPs second group'
    },
    {
      startTime: '16:25',
      finishTime: '17:05',
      title: 'Customer Experience at Europa-Park Resort, Germany’s largest theme park',
      speaker: 'Pascal Weller',
      position: 'Customer Insights Manager & Project Manager Market Research at Europa-Park',
      photo: 'Pascal-Weller.png',
      photoModal: 'Pascal-Weller-modal.png',
      bgImage: 'modal-bg-3.png',
      rating: null,
      slogan: '',
      description: 'Around 5,000 people work at Europa-Park, the Rulantica water world, and the associated themed hotels every day to put a smile on your face. Feedback plays a vital role in continuously improving the guest experience.',
      id: 'Pascal-Weller'
    },
    {
      startTime: '17:05',
      finishTime: '17:30',
      title: 'Screver AI / Future of CX',
      speaker: 'Christian Fillinger',
      position: 'Founder of Capptoo & Screver',
      photo: 'Christian-Fillinger.png',
      photoModal: 'Christian-Fillinger-modal.png',
      bgImage: 'modal-bg-1.png',
      rating: false,
      slogan: '',
      description: '',
      id: 'Screver AI / Future of CX'
    },
    {
      startTime: '',
      finishTime: '',
      title: 'Announcements',
      speaker: '',
      position: '',
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: false,
      slogan: '',
      description: '',
      id: 'Announcements'
    },
    {
      startTime: '15:25',
      finishTime: '15:45',
      title: 'Networking Break',
      subtitle: '<br/> <span>Take a break, connect with people, and check out our theme boxes</span>',
      speaker: '',
      position: '',
      photo: '',
      photoModal: '',
      bgImage: '',
      rating: false,
      slogan: '',
      description: '',
      id: 'AnnouNetworking Breakncements'
    },
  ],
  thirdPart: [{
    startTime: '18:30',
    finishTime: '19:30',
    title: 'Networking Break',
    speaker: null,
    position: null,
    photo: '',
    photoModal: '',
    bgImage: '',
    rating: null,
    slogan: null,
    description: null,
    id: '9'
  }, ]
}
