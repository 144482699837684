import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import Program from 'sections/ScreverOcxProgram/Program';
import BoxSlider from 'sections/ScreverOcxProgram/BoxSlider';
import CarlyBefore from 'sections/ScreverOcxProgram/CarlyBefore';
import CarlySec from 'sections/ScreverOcxProgram/CarlySec';
import BoxList from 'sections/ScreverOcxProgram/BoxList';
import CarlySub from 'sections/ScreverOcxProgram/CarlySub';
import FooterCommon from 'sections/ScreverOcxProgram/FooterCommon';
import Icon from 'components/UI/Icon/index';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

import { PROGRAM } from 'mocks/screver-ocx-program/program';

import 'sections/ScreverOcxProgram/common.scss';

const PAGES = [
  {
    title: 'Participants',
    url: '/events/screver-ocx-program/participants',
  },
  {
    title: '✌🏻Feedback',
    url: '/events/screver-ocx-program/feedback',
  },
  {
    title: 'Casino instructions',
    url: '/events/screver-ocx-program/casino-instructions',
  },
];

const ProgramPage = () => {
  const [isAnchor, setIsAnchor] = useState(false);

  let anchor = 'current-speaker';

  useEffect(() => {
    setTimeout(() => {
      const progs = [...document.querySelectorAll('.js-not-completed')];

      progs.map((item, idx) =>
        idx === 0 && item.classList.contains('js-not-completed')
          ? item.setAttribute('id', anchor)
          : null
      );
    }, 200);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!document.getElementById(anchor)) return;

      typeof window !== 'undefined' &&
        window.addEventListener(
          'scroll',
          () => {
            document.documentElement.scrollTop - 100 >
              document.getElementById(anchor)?.offsetTop ?? 0
              ? setIsAnchor(true)
              : setIsAnchor(false);
          },
          false
        );

      return () =>
        typeof window !== 'undefined' && window.removeEventListener('scroll');
    }, 250);
  }, []);

  const seo = {
    title: 'OCX Event | Program | Screver Feedback Management Solution',
    metaDesc:
      'Learn a Program. We’ll share some exciting news, show off a sneak peek of innovative features, and showcase how AI shapes Voice of the Customer strategies.',
    opengraphUrl: 'https://screver.com/events/screver-ocx-program/program/',
    image:
      'https://wp.screver.com/wp-content/uploads/2023/08/screver-ocx-meta-img.jpeg',
  };

  return (
    <>
      <OcxHelmet />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-program">
        <HeaderCommon title="Program" />

        <main className="">
          <div className="ocx-prog-wrap">
            <div className=""></div>
            <Program data={PROGRAM.firstPart} lastNoLine={true} />
            <BoxSlider />
            <Program data={PROGRAM.secondPart} lastNoLine={true} />
            <BoxList />
            <Program data={PROGRAM.thirdPart} setRightGap={true} />
            <CarlyBefore />
            <CarlySec />
            <CarlySub />
          </div>

          <AnchorLink
            to={`#${anchor}`}
            className={classnames('ocx-prog-anchor', isAnchor ? 'rotate' : '')}
            id="anchor-link-to-next-speaker"
          >
            <Icon name="arrow-to-bottom-in-circle" />
          </AnchorLink>
        </main>

        <FooterCommon linksToPages={PAGES} />
      </div>
    </>
  );
};

export default ProgramPage;
