export const CARLY_SUB = [{
    title: 'live music - electro-funk, deep house & smooth jazz',
    icon: 'music',
  },
  {
    title: 'Poker, Black Jack, Roulette',
    icon: 'cards',
  },
  {
    title: 'grower champaign tasting',
    icon: 'glass',
  },
  {
    title: 'Around 21:00 announcement seven winners of cool prizes',
    icon: 'gift',
  },
]
