import React, { useCallback, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { GatsbyImage /*getImage */ } from 'gatsby-plugin-image';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import axios from 'axios';

import Survey from 'sections/ScreverOcxProgram/Survey';
import ProgramModal from '../ProgramModal';

// import useStaticImage from 'hooks/graphql/useStaticImage';

import {
  listItem,
  content,
  img,
  time,
  title,
  speaker,
  result,
  noHover,
  marginLarge,
  speakerPhoto,
  subtitle,
} from './styles.module.scss';

const Item = ({ item, image }) => {
  // const getStaticImage = useStaticImage();

  const wrapScroll = useRef(null);

  const [isVote, setIsVote] = useState(false);
  const [vote, setVote] = useState(null);
  const [speakerFromStorage /*setSpeakerFromStorage*/] = useState(
    typeof window !== 'undefined' ? localStorage.getItem(item.speaker) : ''
  );
  const [speakerCompleted, setSpeakerCompleted] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  let date = new Date();

  let currentTime = date
    .toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
    .replace(':', '');
  let currentDate = date.getDate() + '/' + Number(date.getMonth() + 1);

  const dateX = '9/3';

  const finishTime = item.finishTime.replace(':', '');

  // sheet.best send API
  const sendToGoogleSheet = useCallback((values) => {
    axios
      .post(
        'https://sheet.best/api/sheets/3404efa5-bd0d-4baa-8d1a-21257b3baba4',
        values
      )
      .then((response) => {
        console.info(response);
      });
  }, []);

  const handleClick = useCallback((e) => {
    const target = e.target.id;

    const voteIdLength = target.length;

    const voteId = target.charAt(voteIdLength - 1);

    const voteSpeaker = target.slice(0, -1);

    setIsVote((state) => !state);

    setVote(voteId);

    localStorage.setItem(voteSpeaker.trim(), voteId);

    const dataToSend = {
      date: new Date().toString(),
      title: item.title,
      speaker: voteSpeaker.trim(),
      vote: voteId,
    };

    sendToGoogleSheet(dataToSend);

    console.info(dataToSend);
  }, []);

  const openModal = useCallback(() => {
    if (item.speaker) {
      setIsOpenModal((state) => !state);
    }
  }, []);

  useEffect(() => {
    if (currentDate === dateX) {
      if (Number(currentTime) > Number(finishTime)) {
        setSpeakerCompleted((state) => !state);
      }
    }

    if (
      currentDate === '10/3' ||
      currentDate === '11/3' ||
      currentDate === '12/3'
    ) {
      setSpeakerCompleted((state) => !state);
    }
  }, []);

  useEffect(() => {
    const refs = wrapScroll.current;

    if (isOpenModal) {
      disableBodyScroll(refs, { reserveScrollBarGap: true });

      return () => {
        enableBodyScroll(refs);
      };
    }
  }, [isOpenModal]);

  return (
    <div
      ref={wrapScroll}
      className={!speakerCompleted ? 'js-not-completed' : ''}
    >
      <div
        className={classnames(listItem, !item.speaker && noHover)}
        onClick={openModal}
        id={
          item.speaker
            ? `modal-${item.startTime}-${item.finishTime}`
            : `no-modal-${item.startTime}-${item.finishTime}`
        }
      >
        <div className={content}>
          {item.startTime && item.finishTime ? (
            <p
              className={classnames(
                time,
                'font-aeroport',
                speakerCompleted
                  ? 'ocx-prog-completed-time'
                  : 'ocx-prog-main-color',
                item.speaker ? marginLarge : ''
              )}
            >
              {item.startTime}-{item.finishTime}
            </p>
          ) : null}

          {item.title ? (
            <p className={classnames(title, 'font-epiloque')}>{item.title}</p>
          ) : null}

          {item.subtitle ? (
            <div
              className={classnames(subtitle, 'font-aeroport')}
              dangerouslySetInnerHTML={{ __html: item.subtitle }}
            ></div>
          ) : (
            <></>
          )}

          {item.speaker ? (
            <p className={classnames(speaker, 'font-aeroport')}>
              {item.speaker}
            </p>
          ) : null}
        </div>

        {item.photo ? (
          <div className={img}>
            <GatsbyImage
              className={speakerPhoto}
              image={image(item.photo)}
              alt={item.speaker}
            />

            {isVote ? (
              // <GatsbyImage
              //   className={result}
              //   objectFit="cover"
              //   image={getImage(getStaticImage(`ocx/rating-${vote}.png`))}
              //   alt={item.speaker}
              // />

              <img
                className={result}
                src={`/images/ocx-event-program/rating-${vote}.png`}
                alt={item.speaker}
              />
            ) : (
              <></>
            )}

            {speakerFromStorage ? (
              // <GatsbyImage
              //   className={result}
              //   image={getImage(
              //     getStaticImage(`ocx/rating-${speakerFromStorage}.png`)
              //   )}
              //   alt={item.speaker}
              // />

              <img
                className={result}
                src={`/images/ocx-event-program/rating-${speakerFromStorage}.png`}
                alt={item.speaker}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>

      {item.speaker && !isVote && !speakerFromStorage && speakerCompleted ? (
        <Survey speaker={item.speaker} handleVote={handleClick} />
      ) : (
        <></>
      )}

      {isOpenModal && item.speaker ? (
        <ProgramModal
          data={item}
          handleVote={handleClick}
          handleClose={openModal}
          speakerCompleted={speakerCompleted}
          speakerFromStorage={speakerFromStorage}
          isVote={isVote}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Item;
