import React from 'react';
import classnames from 'classnames';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// import useStaticImage from 'hooks/graphql/useStaticImage';

import {
  section,
  inner,
  time,
  description,
  title,
  image,
  list,
} from './styles.module.scss';

import { CARLY } from 'mocks/screver-ocx-program/carly';

const CarlySec = () => {
  // const getStaticImage = useStaticImage();

  return (
    <section className={section}>
      <div className={inner}>
        <p className={classnames(time, 'font-aeroport')}>{CARLY.time}</p>
        <p className={classnames(description, 'font-epiloque')}>
          {CARLY.description}
        </p>
        <p className={classnames(title, 'font-epiloque')}>{CARLY.title}</p>
      </div>

      {/* <GatsbyImage
        className={image}
        image={getImage(getStaticImage(`ocx/carly.png`))}
        alt={'Carly Simone banner'}
      /> */}

      <img
        className={image}
        src={`/images/ocx-event-program/carly.png`}
        alt={'Carly Simone banner'}
      />

      <div className={list}>
        <ul>
          {CARLY.genre.length > 0 ? (
            [
              ...CARLY.genre,
              ...CARLY.genre,
              ...CARLY.genre,
              ...CARLY.genre,
              ...CARLY.genre,
            ].map((item, idx) => {
              return <li key={item + idx}>{item}</li>;
            })
          ) : (
            <></>
          )}
        </ul>
      </div>
    </section>
  );
};

export default CarlySec;
