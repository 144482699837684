// extracted by mini-css-extract-plugin
export var modal = "styles-module--modal--3WbrE";
export var closeBtn = "styles-module--closeBtn--2Anaw";
export var modalGap = "styles-module--modalGap--3wRjN";
export var header = "styles-module--header--3-RdB";
export var name = "styles-module--name--bfA0Y";
export var position = "styles-module--position--VJ8LL";
export var bgImage = "styles-module--bgImage--BIeke";
export var image = "styles-module--image--3nFid";
export var content = "styles-module--content--z87pO";
export var time = "styles-module--time--1f1_h";
export var slogan = "styles-module--slogan--3vm4J";
export var description = "styles-module--description--2yAq4";
export var anonymous = "styles-module--anonymous--3ByGl";
export var successStyle = "styles-module--successStyle--2hY5i";
export var headerInner = "styles-module--headerInner--NcR8z";