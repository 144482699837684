export const BOXES = [{
  imageSmall: 'box-small-1',
  imageFull: 'box-full-1',
  title: 'Customer and Employee Experience',
  room: 'Room K6',
  description: 'Disruptive changes or how to design in a  new era'
}, {
  imageSmall: 'box-small-2',
  imageFull: 'box-full-2',
  title: 'Design',
  room: 'Room K7',
  description: 'Prominent  Trends in VoC/VoE 2023'
}, {
  imageSmall: 'box-small-3',
  imageFull: 'box-full-3',
  title: 'Startup Factory',
  room: 'Room K4',
  description: 'Prominent  Trends in  Pharma in  2023'
}, {
  imageSmall: 'box-small-4',
  imageFull: 'box-full-4',
  title: 'Value in Pharma/Healthcare ',
  room: 'Room K8',
  description: 'From Idea to Market. Prominent  Trends in  2023'
}, ];
